import React from "react"

import Layout from "../components/layout"
import Summary from "../components/summary"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Featured from "../components/featured"
import FeaturedDetails from "../components/featured-details"
import CallToAction from "../components/call-to-action"

// import PlayIcon from "../images/icons/play-icon.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <Hero
      imagePath="hero/hero.png"
      title="We create"
      coloredTitle={["Passion", "Fun", "Entertainment", "Power", "Games"]}
      large
      typewriterAnimation
      // icon={<PlayIcon />}
    />
    <Summary />
    <Featured imagePath="featured/prom_clash_color.jpg" large>
      <FeaturedDetails
        imagePath="games/football-clash/logo.png"
        title="Over 1M Downloads!"
        description="Let's play?"
        callToAction={
          <CallToAction
            text="Play now!"
            href="https://apps.apple.com/us/app/football-clash-mobile-soccer/id1540569877"
            blank
          />
        }
        large
      />
    </Featured>
  </Layout>
)

export default IndexPage
