import React from "react"
import { createStyles, WithStyles, withStyles } from "@material-ui/styles"
import StorySVG from "../images/icons/story.svg"
import GamesSVG from "../images/icons/games.svg"
import TeamSVG from "../images/icons/team.svg"

const styles = createStyles({
  summary: {
    padding: 46,
  },
  header: {
    fontWeight: 400,
    fontSize: "1.45rem",
    lineHeight: "2rem",
    textAlign: "center",
    color: "#898989",
  },
  headerTitle: {
    fontWeight: 800,
    fontSize: "2.3rem",
    lineHeight: "3.28rem",
    color: "#000000",
    paddingBottom: 20,
  },
  coloredHeaderTitle: {
    color: "#FF7900",
  },
  content: {
    display: "grid",
    gridTemplateColumns: "33.3% 33.3% 33.3%",
    marginTop: 100,
    "@media (max-width:959.95px)": {
      marginTop: 20,
      gridTemplateColumns: "100%",
    },
  },
  contentItemContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentItem: {
    marginTop: 150,
    position: "relative",
    display: "flex",
    height: "100%",
    alignItems: "center",
    flexDirection: "column",
    width: 280,
    padding: 20,
    textAlign: "center",
    fontWeight: 400,
    color: "#898989",
    "@media (max-width:959.95px)": {
      marginTop: 0,
    },
  },
  iconContainer: {
    position: "absolute",
    bottom: "100%",
    display: "flex",
    "@media (max-width:959.95px)": {
      position: "initial",
    },
  },
  gamesIcon: {
    position: "relative",
    top: 9,
    left: 7.5,
  },
  contentTitle: {
    fontSize: "1.2rem",
    fontWeight: 900,
    lineHeight: "3.28rem",
    textTransform: "uppercase",
    color: "#212121",
  },
  contentDescription: {
    fontSize: "1rem",
    lineHeight: "1.8rem",
  },
})

export interface Props {}

type PropsAndStyles = Props & WithStyles<typeof styles>

const Summary = ({ classes }: PropsAndStyles) => (
  <div className={classes.summary}>
    <div className={classes.header}>
      <div className={classes.headerTitle}>
        Fully Charged
        <div className={classes.coloredHeaderTitle}>Game Development</div>
      </div>
      <div>Let's create hit games, together!</div>
    </div>

    <div className={classes.content}>
      <div className={classes.contentItemContainer}>
        <div className={classes.contentItem}>
          <div className={classes.iconContainer}>
            <StorySVG />
          </div>
          <div className={classes.contentTitle}>Our Story</div>
          <div className={classes.contentDescription}>
            Founded in Portugal in January 2020 by 4 motivated individuals, with
            a steady growth since the start.
          </div>
        </div>
      </div>

      <div className={classes.contentItemContainer}>
        <div className={classes.contentItem}>
          <div className={classes.iconContainer}>
            <GamesSVG className={classes.gamesIcon} />
          </div>
          <div className={classes.contentTitle}>Games</div>
          <div className={classes.contentDescription}>
            Experts on Sports and Puzzle games, with a tremendous focus on shipping quality titles.
          </div>
        </div>
      </div>

      <div className={classes.contentItemContainer}>
        <div className={classes.contentItem}>
          <div className={classes.iconContainer}>
            <TeamSVG />
          </div>
          <div className={classes.contentTitle}>Team</div>
          <div className={classes.contentDescription}>
            A highly talented and passionate group of people, constantly
            striving for success.
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default withStyles(styles)(Summary)
